
import pageRenderer from "@/components/views/subscriptions/modals/buyProcess/components/pageRenderer";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  name: "BuyFormSelect",
})
export default class BuyFormSelect extends Vue {
  @Prop({ default: [] })
  private options!: string[];
  @Prop({ default: "" })
  private selectId!: string;
  @Prop({ default: "" })
  private forceSelect!: string;
  @Prop({ default: "-- Select --" })
  private placeHolderText!: string;
  @Watch("options")
  onOptions(newValue: string[], oldValue: string[]) {
    if (oldValue.length === 0 && newValue.length > 0) {
      this.isDisabled = false;
      return;
    }
    if (oldValue.length > 0 && newValue.length === 0) {
      this.isDisabled = true;
      this.selected = "";
      return;
    }
  }
  @Watch("forceSelect")
  onForceSelect(newValue: string, oldValue: string) {
    if (newValue) {
      this.selected = newValue;
    }
  }
  isDisabled = false;
  selected = "";

  get placeHolder() {
    return this.isDisabled ? "Outside USA or Canada" : this.placeHolderText;
  }

  mounted() {
    this.isDisabled = this.options.length === 0;
    const querySelector: HTMLSelectElement | null = document.querySelector(this.selectId);
    if (!querySelector) {
      return;
    }
    this.selected = querySelector.value;
  }

  selectedCountry() {
    const querySelector = document.querySelector(this.selectId);
    if (!querySelector) {
      return;
    }
    pageRenderer.selectItemByValue(querySelector, this.selected);
    querySelector.dispatchEvent(new Event("change"));
  }
}
