
import LoadingMask from "@/components/common/LoadingMask.vue";
import QuickPurchasePageTitle from "@/components/landingPages/quickPurchase/QuickPurchasePageTitle.vue";
import pageRenderer from "@/components/views/subscriptions/modals/buyProcess/components/pageRenderer";
import { LoadingMaskProps } from "@/types/loadingMask";
import { AnyObject, QuickPurchaseData } from "@/types/localtypes";
import { JFCheckBox } from "jfrog-ui-vue-essentials";
import { JPayPurchaseResponse, JPCInstance, PaymentType } from "@jfrog-ba/myjfrog-common";
import { Component, Inject, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

interface AdditionalProductConfig {
  label: string;
  name: string;
  model: boolean;
  onCbChange: (checked: boolean) => void;
  currency: string;
  price: number;
  period: string;
  dataGtmEvent: string;
}

@Component({
  name: "QuickPurchaseCheckout",
  components: { QuickPurchasePageTitle, JFCheckBox, LoadingMask },
})
export default class QuickPurchaseCheckout extends Vue {
  @Inject() readonly globalBus!: Vue;
  @Inject() readonly quickPurchaseBus!: Vue;
  @Getter("quickPurchaseData", { namespace: "quickPurchase" })
  quickPurchaseData!: QuickPurchaseData;
  @Action("setHasUpgraded", { namespace: "quickPurchase" })
  setHasUpgraded!: (hasUpgraded: boolean) => Promise<void>;
  @Prop() private planName!: string;

  @Prop() private paymentType!: PaymentType;
  @Prop() private planId!: string;
  @Prop() private additionalPlanId!: string;
  @Prop() private additionalPaymentType!: PaymentType;
  @Prop() private purchaseType!: string;
  @Prop() private purchaseCurrency!: string;
  @Prop() private purchaseAmount!: string;
  @Prop({ default: "false" }) private isInPromotionStr!: string;

  paymentObjectWrapperId = "payment-object-wrapper-buy-process";
  jpcLoaded = false;
  paymentScript = "";
  buyFormDisplayed = false;
  isFirstStep = true;
  minHeight = 200;
  isAgreePrivacy = false;
  agreeError = false;
  isLoading = true;
  displayBackToPricingBtn = true;
  tocActivated = false;
  currentPaymentType = this.paymentType;
  currentRatePlanId = this.planId;
  addXrayPremium = false;
  forceDisableSubmitButton = false;

  get dataGtmPage() {
    return `quick-purchase-checkout-${this.$utils.toKebabCase(this.planName, true)}`;
  }

  get xrayPremiumCbGtmEvent() {
    return `${this.dataGtmPage}|xray-premium-checkbox`;
  }

  get nextGtmEvent() {
    const buttonLabel = this.isFirstStep ? "next" : "upgrade";
    return `${this.dataGtmPage}|${buttonLabel}-button`;
  }

  get backToPricingGtmEvent() {
    return `${this.dataGtmPage}|back-to-pricing-button`;
  }

  get isInPromotion() {
    return "true" === this.isInPromotionStr;
  }

  get displayPromotionPriceTopDisclaimer() {
    return !this.isFirstStep && this.isInPromotion;
  }

  async mounted() {
    this.quickPurchaseBus.$emit("shouldDisplayHostedPlanCta", false);
    this.initDefaultValues();

    // @ts-ignore //
    if (window.JPC) {
      this.jpcLoaded = true;
    } else {
      try {
        this.paymentScript = await this.$jfPayments.fetchPaymentsScripts();
        if (this.paymentScript) {
          eval(this.paymentScript);
          this.jpcLoaded = true;
        }
      } catch (e) {
        this.$log.error(e);
        this.notifyError(this.$jfMessages.jpay_loading_failed);
        this.toPlans();
      }
    }

    if (this.jpcLoaded) {
      this.loadJPCBuyForm();
    }
  }

  initDefaultValues() {
    if (this.displayingEnterprisePlan) {
      this.addXrayPremium = true;
      this.additionalProductConfig && this.additionalProductConfig.onCbChange(this.addXrayPremium);
    }
  }

  onAddXrayPremiumChange(checked: boolean) {
    if (!this.additionalPlanId) {
      throw new Error("no additional payment types found");
    }
    this.currentRatePlanId = checked ? this.additionalPlanId : this.planId;
    this.currentPaymentType = checked ? (this.additionalPaymentType as PaymentType) : this.paymentType;
  }

  get additionalProductConfig(): AdditionalProductConfig | void {
    if (this.displayAdditionalProduct) {
      if (this.displayingEnterprisePlan) {
        return {
          label: "Add Security Pack",
          name: "addXrayPremium",
          model: this.addXrayPremium,
          onCbChange: this.onAddXrayPremiumChange,
          currency: "$",
          price: 500,
          period: this.periodLabel,
          dataGtmEvent: this.xrayPremiumCbGtmEvent,
        };
      }
    }
    return;
  }

  get additionalProductCbLabel() {
    if (this.displayingEnterprisePlan) {
      return "Add Security Pack";
    }
    return;
  }
  get displayingEnterprisePlan() {
    return ["ENTERPRISE_MONTHLY", "ENTERPRISE_X_MONTHLY"].includes(this.currentPaymentType);
  }
  get displayAdditionalUsagePrices() {
    return this.isFirstStep && this.displayingEnterprisePlan;
  }

  get displayAdditionalProduct() {
    return this.isFirstStep && this.displayingEnterprisePlan;
  }

  get displayAdditionalProductCheckbox() {
    return false;
  }

  get additionalUsagePricesItems(): string[] | void {
    if (this.displayingEnterprisePlan) {
      return ["Additional usage Pricing:", "Storage: $1.45/GB", "Data Transfer: $0.45/GB", "CI/CD Minutes: $0.007/Min"];
    }
    return;
  }

  get jpcFormWrapperClasses() {
    const classes = [];
    if (this.isFirstStep) {
      classes.push("firstStep", "bg-mjf-blue-extra-light");
    }
    return classes;
  }

  get headerWrapperStyles() {
    const styles: AnyObject = {};
    if (this.isFirstStep) {
      styles.minHeight = "120px";
    }
    return styles;
  }

  isMobile() {
    return this.$mq === "mobile";
  }

  get periodLabel() {
    if (!this.purchaseType) {
      return "";
    }
    return this.purchaseType === "month" ? "/mo" : `/${this.purchaseType}`;
  }

  get wrapperStyles() {
    const minWidth = this.isMobile() ? 200 : 500;
    return {
      minWidth: `${minWidth}px`,
    };
  }

  get jpcLogErrorPrefix() {
    return `jpc-callback-quick-purchase [${this.quickPurchaseData.serverName}] : `;
  }

  toPlans() {
    this.$router.push({ path: "pricing" });
  }

  get loadingMaskProps(): LoadingMaskProps {
    return {
      loading: this.isLoading,
      zIndex: 10000, //to be on top of Zuora card iframe
    };
  }

  get nextStepBtnLabel() {
    return this.isFirstStep ? "next" : "upgrade my plan";
  }

  loadJPCBuyForm() {
    // @ts-ignore
    const jpc: JPCInstance = new JPC(
      this.getJPCPrepopulate(),
      this.getJPCMessages(),
      this.getJPCOptions(),
      this.loadJPCCallbacks(),
    );
    setTimeout(() => {
      jpc.loadBuyCustomerDetailsForm();
    }, 2000);
  }

  getJPCOptions(): JPCInstance["options"] {
    return {
      divJpcFormId: this.paymentObjectWrapperId,
      placeHoldersActive: false,
      optionalHeaders: {
        "x-quick-purchase-token": this.quickPurchaseData.token,
        "x-quick-purchase-servername": this.quickPurchaseData.serverName,
      },
      creditCardIframeWidth: null,
      creditCardIframeHeight: 380,
    };
  }

  getJPCPrepopulate(): JPCInstance["prepopulate"] {
    const ratePlanId = this.currentRatePlanId || this.planId;
    return {
      firstName: " ",
      lastName: " ",
      email: "dummy@jfrog.com",
      confirmEmail: "dummy@jfrog.com",
      phone: "",
      country: "",
      city: "",
      state: "",
      address: "",
      zip: "",
      vatId: null,
      company: "",
      billingFirstName: "",
      billingLastName: "",
      billingEmail: "",
      billingConfirmEmail: "",
      billingPhone: "",
      billingAddress: "",
      billingZip: "",
      billingState: null,
      billingCountry: "",
      billingCity: "",
      billingCompany: "",
      ratePlanId,
      additionalRatePlanIds: [],
    };
  }

  getJPCMessages(): JPCInstance["messages"] {
    return {
      label_totalBeforeTax: "Total",
      label_tax: "Estimated Tax To Be Collected",
      label_amount: "Total",
    };
  }

  get nextStepBtnDisabled() {
    return this.isLoading || this.forceDisableSubmitButton;
  }

  loadJPCCallbacks(): JPCInstance["callbacks"] {
    return {
      buyCustomerDetailsForm_hideOverlayFor3DS: () => {
        this.forceDisableSubmitButton = true;
        setTimeout(() => {
          this.setLoading(false);
        }, 3000);
      },
      buyCustomerDetailsForm_render: (html: HTMLDivElement) => {
        return pageRenderer.renderPage(html);
      },
      buyCustomerDetailsForm_renderPriceElement: (price: string) => {
        return price.replace("$", "<span class='currency'>$</span>");
      },
      buyCustomerDetailsForm_transformRatePlanId: (ratePlanId: string) => {
        return this.currentRatePlanId || this.planId;
      },
      buyCustomerDetailsForm_submitCheckoutSuccess: (response: JPayPurchaseResponse) => {
        this.handlePurchaseSuccess(response);
        this.forceDisableSubmitButton = false;
      },
      buyCustomerDetailsForm_loadSuccess: () => {
        this.buyFormDisplayed = true;
        pageRenderer.renderSelect();
        this.createEvents();
        this.hideStateIfNotNeeded();
        this.setLoading(false);
      },
      buyCustomerDetailsForm_loadError: (status: number, errorMessage: string) => {
        this.buyFormDisplayed = false;
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}error : ${status} => ${errorMessage || "no message"}`;
        this.$log.error(jpcErrorMessage);
        this.setLoading(false);
        if (status === 401) {
          this.$jfUsers.handleUnauthorized();
          return;
        }
        this.notifyError(this.$jfMessages.jpay_buy_form_loading_failed);
      },
      buyCustomerDetailsForm_submitError: (status: number, errorMessage: string, exceptionClass: string) => {
        const jpcErrorMessage = `${this.jpcLogErrorPrefix}Error : ${status} => ${errorMessage || "no message"}${
          exceptionClass ? ` (exception : ${exceptionClass})` : ""
        }`;

        this.$log.error(jpcErrorMessage);
        this.setLoading(false);

        if (status === 401) {
          this.$jfUsers.handleUnauthorized();
          return;
        }

        if (exceptionClass && exceptionClass.includes("TaxValidationErrorException")) {
          // we don't show error notification because JPay will display UI errors into the form.
          return;
        }

        this.notifyError(this.$jfMessages.jpay_buy_form_submit_failed);
      },
      buyCustomerDetailsForm_submitCheckoutError: (message: string) => {
        this.$log.error(`${this.jpcLogErrorPrefix}${message}`);
        this.setLoading(false);

        this.notifyError(this.$jfMessages.extractPaymentsError(message));
        this.forceDisableSubmitButton = false;
      },
      buyCustomerDetailsForm_beforeSubmitCheckout: () => {
        this.setLoading(true);
        return true;
      },
      buyCustomerDetailsForm_sameInfosClicked: () => {
        pageRenderer.forceSelect(pageRenderer.countryBilling, "forceSelect");
        pageRenderer.pushOptions(pageRenderer.stateBilling, "options");
        pageRenderer.forceSelect(pageRenderer.stateBilling, "forceSelect");
      },
      buyCustomerDetailsForm_beforeSubmit: () => {
        this.setLoading(true);
        return true;
      },

      buyCustomerDetailsForm_beforeCheckForm: () => {
        return true;
      },

      buyCustomerDetailsForm_beforeChangePage: () => {
        this.isFirstStep = false;
        this.displayBackToPricingBtn = false;
        return true;
      },
      buyCustomerDetailsForm_afterChangePage: () => {
        if (this.displayPromotionPriceTopDisclaimer) {
          pageRenderer.createPromotionDisclaimer();
        }
        setTimeout(() => {
          this.setLoading(false);
        }, 1500);
        return true;
      },
      buyCustomerDetailsForm_beforePrevCheckout: () => {
        return true;
      },
      global_selectStateChanged: (select: string) => {},
      global_selectCountryChanged: (select: string) => {},
    };
  }

  async handlePurchaseSuccess(jpayPurchaseForm: JPayPurchaseResponse) {
    try {
      this.setLoading(true);
      const quickPurchaseResponse = await this.$jfSubscriptions.quickPurchaseCloud({
        marketoCookie: this.$jfMarketo.getCookie(),
        newPaymentType: this.currentPaymentType || this.paymentType,
        purchaseForm: jpayPurchaseForm,
        serverName: this.quickPurchaseData.serverName,
        token: this.quickPurchaseData.token,
      });
      this.$jfWebeyez.send({
        goal_key: "upgrade",
        isSuccess: true,
        customData: [{ key: "accountNumber", value: this.quickPurchaseData.serverName }],
      });
      this.toThankYou(quickPurchaseResponse.platformUrl);
    } catch (e) {
      this.$log.error(`${this.jpcLogErrorPrefix} on success handler : ${e.message}`);
      this.setLoading(false);
      this.notifyError(this.$jfMessages.subscriptions_upgrade_error_unknown);
    }
  }

  async toThankYou(platformUrl: string) {
    await this.setHasUpgraded(true);
    await this.$router.push({ name: "quick-purchase-thank-you", params: { planName: this.planName, platformUrl } });
  }

  setLoading(loading: boolean) {
    this.isLoading = loading;
    if (this.displayPromotionPriceTopDisclaimer) {
      pageRenderer.togglePromotionDisclaimerLoading(this.isLoading);
    }
  }

  get paymentObjectWrapperClasses() {
    const classes = [];
    if (this.isFirstStep) {
      classes.push("row");
    } else {
      classes.push("w-100");
    }
    return classes;
  }

  get shouldDislayAgreementCb() {
    return this.tocActivated && this.isFirstStep && this.buyFormDisplayed;
  }

  get buyFormWrapperStyle() {
    return {
      minHeight: this.minHeight + "px",
    };
  }

  onAgreeChange() {
    this.agreeError = false;
  }

  handleNextStep() {
    const sameInfoCheckBox = document.getElementById(
      "payment-object-wrapper-buy-processjpc-checkbox-sameInfo",
    ) as HTMLInputElement;

    if (!sameInfoCheckBox) {
      return;
    }
    // trick to click the hidden checkbox
    if (sameInfoCheckBox.checked) {
      sameInfoCheckBox.click();
    }
    sameInfoCheckBox.click();

    if (this.isFirstStep) {
      const btnNext = document.getElementById("payment-object-wrapper-buy-processjpc-btn-next");
      if (this.tocActivated && !this.isAgreePrivacy) {
        this.validateCheckBox();
        return;
      }
      btnNext && btnNext.click();
    } else {
      this.$jfNotification.clearAll();
      const btnSubmit = document.getElementById("payment-object-wrapper-buy-processjpc-btn-submit");
      btnSubmit && btnSubmit.click();
    }
  }

  validateCheckBox() {
    this.agreeError = true;
  }

  get agreeCbLabel() {
    return `I have read and agree to the
<a class="agreeLink" href="https://jfrog.com/artifactory/cloud-eula/" target="_blank">Terms and Conditions</a> and the <a class="agreeLink" href="https://jfrog.com/privacy-policy/" target="_blank">Privacy Policy</a>`;
  }

  createEvents() {
    const countrySelect = document.querySelector(pageRenderer.country);
    const countryBillingSelect = document.querySelector(pageRenderer.countryBilling);
    const additionalBillingSelect = document.querySelector(pageRenderer.additionalBillingSelect);

    if (countrySelect && countryBillingSelect) {
      countrySelect.addEventListener("change", () => {
        pageRenderer.pushOptions(pageRenderer.state, "options");
        this.hideStateIfNotNeeded();
      });
      countryBillingSelect.addEventListener("change", () =>
        pageRenderer.pushOptions(pageRenderer.stateBilling, "options"),
      );
    }
    if (additionalBillingSelect) {
      (additionalBillingSelect as HTMLSelectElement).disabled = false;
    }
  }

  hideStateIfNotNeeded() {
    const stateContainerId = "payment-object-wrapper-buy-processjpc-details-state";
    const stateInput = document.getElementById(
      "payment-object-wrapper-buy-processjpc-input-state",
    ) as HTMLSelectElement;
    stateInput && this.toggleState(stateContainerId, !stateInput.disabled);
  }

  toggleState(id: string, display: boolean) {
    const stateDiv = document.getElementById(id) as HTMLDivElement;
    if (!stateDiv) {
      return;
    }
    const classListAction = display ? "remove" : "add";
    stateDiv.classList[classListAction]("jpc-hidden-field");
  }

  notifyError(errorMessage: string) {
    this.globalBus.$emit("notifyError", errorMessage);
    this.$jfWebeyez.send({
      goal_key: "upgrade",
      isSuccess: false,
      errorMessage: errorMessage,
      customData: [{ key: "accountNumber", value: this.quickPurchaseData.serverName }],
    });
  }
}
