import { Vue } from "vue-property-decorator";
import BuyFormSelect from "./BuyFormSelect.vue";

export interface RenderPageGridConfig {
  nbColumns: number;
  gridOrderMap?: { [key: string]: number };
  idFragmentsToHide?: string[];
  classFragmentsToHide?: string[];
  hideTitle?: boolean;
}

type PlaceHolderMap = { [key: string]: string };

export default {
  ratePlanId: "#payment-object-wrapper-buy-processjpc-input-ratePlanId",
  country: "#payment-object-wrapper-buy-processjpc-input-country",
  countryBilling: "#payment-object-wrapper-buy-processjpc-input-billingCountry",
  state: "#payment-object-wrapper-buy-processjpc-input-state",
  stateBilling: "#payment-object-wrapper-buy-processjpc-input-billingState",
  additionalBilling: "#payment-object-wrapper-buy-processjpc-details-additionalRatePlanIds",
  additionalBillingSelect: "#payment-object-wrapper-buy-processjpc-input-additionalRatePlanIds",
  instances: {} as { [key: string]: any },

  renderPage(html: HTMLDivElement, gridConfig?: RenderPageGridConfig) {
    if (!!gridConfig) {
      this.wrapHtmlInGrid(html, gridConfig);
    } else {
      this.wrapHtml(html);
    }
    this.addExtraClasses(html);

    if (!gridConfig || !gridConfig.hideTitle) {
      this.createPageTitle(
        html,
        "#payment-object-wrapper-buy-processjpc-details-sameInfo",
        "Billing Details",
        "billing-buy-form",
      );
    }

    return html;
  },

  addExtraClasses(html: HTMLDivElement) {
    const inputs = html.querySelectorAll("input");
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].classList.add("form-control");
    }
  },

  renderSelect(placeHoldersText?: PlaceHolderMap) {
    this.addSelect(this.country, placeHoldersText);
    this.addSelect(this.countryBilling, placeHoldersText);
    this.addSelect(this.state, placeHoldersText);
    this.addSelect(this.stateBilling, placeHoldersText);

    const inputs = document.querySelectorAll(".buy-form-vselect input");
    for (let index = 0; index < inputs.length; index++) {
      const input: Element = inputs[index];
      input.setAttribute("no-validation", "");
    }
    // document.querySelectorAll(".buy-form-vselect input").forEach((input: Element) => {});
  },
  createPageTitle(html: HTMLDivElement, firstElementId: string, title: string, className: string): void {
    const firstElement = html.querySelector(firstElementId);
    if (!firstElement) {
      return;
    }
    const parent = firstElement.parentNode;
    if (!parent) {
      return;
    }
    const elem = document.createElement("div");
    elem.innerText = title;
    elem.classList.add(className, "jpc-elm-page", "customer-details-page");

    parent.insertBefore(elem, firstElement);
    if (firstElementId === "#payment-object-wrapper-buy-processjpc-details-firstName") {
      const div = document.createElement("div");
      div.innerHTML = "&nbsp;";
      parent.insertBefore(div, firstElement);
    }
  },
  wrapHtml(html: HTMLDivElement) {
    let dv = document.createElement("div");
    const divs = html.querySelectorAll(".customer-details-page");

    dv.classList.add("col-md-12", "col-12");
    for (let i = 0; i < divs.length; i++) {
      if (divs[i].id === "payment-object-wrapper-buy-processjpc-details-sameInfo") {
        html.appendChild(dv);
        dv = document.createElement("div");
        dv.classList.add("col-md-6", "col-12", "buy-same-billing-details-wrapper");
      }
      dv.appendChild(divs[i]);
    }
    html.appendChild(dv);
  },

  createPromotionDisclaimer() {
    const zuoraPaymentWrapper = document.querySelector("#zuora_payment");
    if (!zuoraPaymentWrapper) {
      return;
    }
    const parent = zuoraPaymentWrapper.parentNode;
    if (!parent) {
      return;
    }
    const wrapper = document.createElement("div");
    wrapper.classList.add("jpc-elm-page", "checkout-page", "loading");
    wrapper.id = "buy-process-jpc-promotion-disclaimer-wrapper";

    const content = document.createElement("div");
    content.id = "buy-process-jpc-promotion-disclaimer-content";
    content.classList.add("fx", "fxCenter");
    content.innerText = `Valid for 5 months; JFrog's standard fees apply thereafter.`;

    const separator = document.createElement("div");
    separator.classList.add("separator");

    content.appendChild(separator);
    wrapper.appendChild(content);
    parent.insertBefore(wrapper, zuoraPaymentWrapper);
  },
  togglePromotionDisclaimerLoading(loading: boolean) {
    const promotionWrapper = document.querySelector("#buy-process-jpc-promotion-disclaimer-wrapper");
    if (!promotionWrapper) {
      return;
    }

    if (loading) {
      promotionWrapper.classList.add("loading");
    } else {
      promotionWrapper.classList.remove("loading");
    }
  },

  wrapHtmlInGrid(html: HTMLDivElement, gridConfig: RenderPageGridConfig) {
    const dv = document.createElement("div");
    dv.classList.add("fx");

    const divsCustomerDetailsPage = html.querySelectorAll(".customer-details-page");

    const columnWidth = 100 / gridConfig.nbColumns;
    const idFragmentsToHide = gridConfig.idFragmentsToHide || [];
    const classFragmentsToHide = gridConfig.classFragmentsToHide || [];
    const gridOrderMap = gridConfig.gridOrderMap || {};

    for (let i = 0; i < divsCustomerDetailsPage.length; i++) {
      const div = divsCustomerDetailsPage[i];
      const divId = div.id;
      const classList = div.classList;
      idFragmentsToHide.forEach(idFragmentsToHide => {
        if (divId.includes(idFragmentsToHide)) {
          classList.add("jpc-hidden-field");
        }
      });

      classList.forEach(divClassName => {
        if (divClassName === "billing-buy-form") {
        }
        classFragmentsToHide.forEach(classFragmentToHide => {
          if (divClassName.includes(classFragmentToHide)) {
            classList.add("jpc-hidden-field");
          }
        });
      });

      (div as HTMLDivElement).style.width = `${columnWidth}%`;
      const keyFound = Object.getOwnPropertyNames(gridOrderMap).find(idPartName => divId.indexOf(idPartName) != -1);
      if (keyFound) {
        (div as HTMLDivElement).style.order = `${gridOrderMap[keyFound]}`;
      }

      if (!classList.contains("jpc-hidden-field")) {
        dv.appendChild(div);
      }
    }
    html.appendChild(dv);
  },
  createElement(tagName: string): HTMLElement {
    const div = document.createElement(tagName);
    div.classList.add("customer-details-page", "jpc-container-page", "myClass");
    return div;
  },
  addSelect(selectId: string, placeHoldersText?: PlaceHolderMap) {
    const querySelector = document.querySelector(selectId) as HTMLSelectElement;
    if (!querySelector || !querySelector.parentNode) {
      return;
    }
    // @ts-ignore
    const ComponentClass = Vue.extend(BuyFormSelect);
    const selectedOptions = this.getSelectedOption(querySelector);
    const placeHolder = placeHoldersText && placeHoldersText[selectId];
    const instance = new ComponentClass({
      propsData: { selectId: selectId, options: selectedOptions, placeHolderText: placeHolder },
    });
    instance.$mount();
    this.instances[selectId] = instance;
    querySelector.parentNode.insertBefore(instance.$el, querySelector);
  },

  resetIdError(htmlElementId: string) {
    const querySelector = document.querySelector(`${htmlElementId}-error`) as HTMLLabelElement;
    if (!querySelector) {
      return;
    }

    querySelector.innerHTML = "";
  },

  getSelectedOption(select: HTMLSelectElement) {
    if (select.disabled) {
      return [];
    }
    const options = [];
    for (let i = 0, len = select.options.length; i < len; i++) {
      options.push(select.options[i].value);
    }
    return options.filter(option => option);
  },
  selectItemByValue(elmnt: any, value: any) {
    for (let i = 0; i < elmnt.options.length; i++) {
      if (elmnt.options[i].value === value) {
        elmnt.selectedIndex = i;
        break;
      }
    }
  },
  pushOptions(selectId: string, propName: string) {
    const stateSelect: HTMLSelectElement | null = document.querySelector(selectId);
    if (!stateSelect) {
      return;
    }
    this.instances[selectId][propName] = this.getSelectedOption(stateSelect);
  },
  forceSelect(selectId: string, propName: string) {
    const stateSelect: HTMLSelectElement | null = document.querySelector(selectId);
    if (!stateSelect) {
      return;
    }

    const querySelector: HTMLSelectElement | null = document.querySelector(
      selectId === this.countryBilling ? this.country : this.state,
    );
    if (!querySelector) {
      return;
    }
    this.instances[selectId][propName] = querySelector.value;
  },
};
